import React from 'react';
import './disclaimer-message.scss';

const DisclaimerMessageContent = () => {
  return (
    <>
      <div className="legal-disclaimer-message__overlay" data-component="DisclaimerMessageOverlayContent">
        <p>
          3M personal protective equipment sold through The Home Depot is only intended for US occupational
          workplace use. This 3M personal protective equipment must be used in compliance with the Occupational
          Safety and Health Administration (OSHA) Personal Protective Equipment (PPE) standard (29 CFR 1910.132)
          and all other applicable health
          and safety standards, as well as all user instructions, warnings and limitations accompanying each product.
          It is essential that all product user instructions and government regulations on the use of each product be
          followed in order for the product to help protect the wearer. Misuse of personal protective equipment may
          result in injury, sickness, or death. For correct product selection and use, individuals should consult
          their on-site safety professional or industrial hygienist.
        </p><br />
        <div>
          <span>
            For additional product information, visit
            <a
              className="u__default-link"
              rel="noreferrer"
              href="https://www.3m.com/3M/en_US/worker-health-safety-us/"
              target="_blank"
            > www.3M.com/PPESafety.
            </a>
          </span>
        </div>
      </div>
      <hr />
    </>
  );
};

DisclaimerMessageContent.displayName = 'DisclaimerMessage';

export { DisclaimerMessageContent };
